import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FileInputComponent from 'react-file-input-previews-base64';
import { useField } from 'formik';
import { Button, Text, FormInputWrapper } from './';
import Resizer from 'react-image-file-resizer';


export const FormImageInput = props => {

    const [field, meta] = useField(props.name);
    const [imageChanged, setImageChanged] = useState(false);

    const handleFileSelect = async image => {

        if (props.resize) {
            await Resizer.imageFileResizer(
                image.file,
                props.resize.maxWidth,
                props.resize.maxHeight,
                'JPEG',
                (props.resize.quality ? props.resize.quality : 100),
                0,
                uri => {
                    setImageChanged(true);
                    props.onChange(props.name, uri, true);
                },
                'base64'
            );
        } else {
            setImageChanged(true);
            props.onChange(props.name, image['base64'], true);
        }
    };

    return (
        <FormInputWrapper className={props.className} width={props.width} meta={meta} name={props.name}>
            <Text label={props.label} />
            {field.value && !imageChanged &&
                <div>
                    <img src={field.value} style={{height:"auto", width: "auto", maxWidth: "200px", maxHeight: "200px"}} />
                </div>
            }
            <FileInputComponent
                {...field}
                multiple={false}
                callbackFunction={handleFileSelect}
                accept="image/*"
                labelText=""
                inputName={props.name}
                buttonComponent={<Button secondary small label={props.btnLabel}/>}
                imagePreview={true}
                imageStyle={{height:"auto", width: "auto", maxWidth: "300px", maxHeight: "300px", marginBottom: "10px", "object-fit":"contain"}}
            />
        </FormInputWrapper>
    );
};


FormImageInput.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    btnLabel: PropTypes.string.isRequired,
    className: PropTypes.string,
    width: PropTypes.string
};
