import React, { useEffect, useRef, useState } from 'react';
import { Button, QuestionnaireForm, SettingBtns } from '../components';
import { QuestionnaireChoose } from './QuestionnaireChoose';
import PropTypes from "prop-types";
import { history, store } from "../_library";


export const Questionnaire = (props) => {

    const [questionnaireConfig, setQuestionnaireConfig] = useState([]);
    const [curQuestionIdx, setCurQuestionIdx] = useState(0);
    const { i18n } = store.getState();

    // Selected answer config [[true, true, false], [true, false false], [false, false] ... etc]
    const selectedAnswersArr = useRef([]);

    useEffect(() => {
        props.init() // explained in PropTypes
            .then(() => { // after init passes get the quiz config data
                props.data()
                    .then((res) => {
                        let answers = res.data.answers.answers;
                        const questionnaire = res.data.questionnaire.questionnaire;

                        // Setting currently selected answers
                        selectedAnswersArr.current = answers;

                        // Here it just iterates over all 'questionnaire.options[n].selected' fields and sets them
                        // as defined in 'answers[[true, true, false], [true, false], [false, false] ..etc]'
                        let firstNotSelectedQ = true;
                        if (history.location.search){
                            const params = new URLSearchParams(window.location.search);
                            if (params.has('question')) {
                                const int = parseInt(params.get('question'));
                                if (int.toString() !== 'NaN') {
                                    setCurQuestionIdx(parseInt(params.get('question')) - 1);
                                    firstNotSelectedQ = false;
                                }
                            }
                        }
                        answers.forEach((answerArr, idx) => {
                            if (firstNotSelectedQ && !(answerArr.includes(true))) {
                                setCurQuestionIdx(idx);
                                firstNotSelectedQ = false;
                            }
                            answerArr.forEach((answer, idx2) => {
                                if (answer === true){
                                    questionnaire[idx].lastSelectedIdx = idx2;
                                }
                                questionnaire[idx].options[idx2].selected = answer;
                            })
                        })

                        setQuestionnaireConfig(questionnaire)})
                    .catch(error => {});
            })
            .catch(error => {});
        return () => {
            props.save(selectedAnswersArr.current);
        }
    }, []);

    const countSelectedAnswers = (answerArr) => {
        let i = 0;
        answerArr.forEach(it => {
            if (it.includes(true)) {
                i++;
            }
        })
        return i;
    }

    const submitAnswers = () => {
        props.save(selectedAnswersArr.current)
            .then(() => {
                    props.success(true);
                }
            )
            .catch((err) => {
                props.success(false);
            });
    }

    const skip = () => {
        history.push('/patient/dashboard');
    }

    // Sets question index
    const setQuestion = (idx) => {
        history.push({
            pathname: history.location.pathname,
            search: '?question=' + (parseInt(idx) + 1)
        });
        setCurQuestionIdx(idx);
    }

    // Updates any kind of updates in the form
    const updateForm = (form, idx) => {
        setQuestionnaireConfig(questionnaireConfig => {
            selectedAnswersArr.current[idx] = form.options.map(it => {return it.selected});
            questionnaireConfig[idx] = form
            return [...questionnaireConfig];
        })
    }

    return (
            <div className='2xl:w-3/6 lg:w-4/6 md:w-5/6 w-full mx-auto relative'>
                <div className='relative'>
                    <div className='bg-white relative'>
                        {questionnaireConfig.length > 0 &&
                            <QuestionnaireForm
                                quizConfig={questionnaireConfig[curQuestionIdx]}
                                optionsLength={questionnaireConfig[curQuestionIdx].options.length}
                                updateForm={updateForm}
                                idx={curQuestionIdx}
                                lang={i18n.lang}
                            />
                        }
                        {curQuestionIdx !== questionnaireConfig.length - 1 &&
                            <div className='absolute bottom-4 right-4'>
                                <Button onClick={() => setQuestion(curQuestionIdx + 1)} color={'neutral'} label={'sv.questionnaire.next'} className='w-40' />
                            </div>
                        }
                        {curQuestionIdx !== 0 &&
                            <div className='absolute bottom-4 left-4'>
                                <Button onClick={() => setQuestion(curQuestionIdx - 1)} color={'neutral'} label={'sv.questionnaire.previous'} className='w-40' />
                            </div>
                        }
                    </div>
                </div>
                <div className='min-w-69 mt-5 2xl:mt-0 2xl:w-80 w-full mx-auto 2xl:absolute top-0 -right-96 2xl:w-92'>
                    {questionnaireConfig.length > 0 &&
                        <QuestionnaireChoose
                            amount={questionnaireConfig.length}
                            setQuestion={setQuestion}
                            selected={curQuestionIdx}
                            selectedAnswersArr={selectedAnswersArr.current}
                        />
                    }
                    <div className={'w-full py-2 flex flex-row-reverse'}>
                        <div className='hidden pl-1 2xl:inline-block w-1/2'>
                            <Button onClick={() => {(curQuestionIdx === questionnaireConfig.length - 1) ? submitAnswers() : setQuestion(curQuestionIdx + 1)}}
                                    color={'neutral'} label={(curQuestionIdx === questionnaireConfig.length - 1) ? 'sv.questionnaire.submit' : 'sv.questionnaire.next'} className='w-full'
                            />
                        </div>
                        <div className='hidden pr-1 2xl:inline-block w-1/2'>
                            <Button onClick={() => setQuestion(curQuestionIdx - 1)} color={'neutral'} label={'sv.questionnaire.previous'} className='w-full'
                                    disabled={curQuestionIdx === 0} />
                        </div>
                    </div>
                    <table className='w-full'>
                        <tbody>
                            <tr>
                                <td>
                                    <Button onClick={() => submitAnswers()} staticLabel={'(' + countSelectedAnswers(selectedAnswersArr.current) + '/' + questionnaireConfig.length + ')'} label='sv.questionnaire.submit' className='mx-auto 2xl:w-full md:w-full w-full lg:w-2/4'/>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <Button color={'neutral'} onClick={() => skip()} label='sv.questionnaire.skip' className='mt-1.5 mx-auto 2xl:w-full md:w-full w-full lg:w-2/4'/>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
    )
}

// >> data (GET) function is used to retrieve the quiz config
// >> data function returns a promise with response =>
//   [{
//   question: el.question,
//   multiselect: el.multiselect,
//   lastSelectedIdx: el.lastSelectedIdx,
//   options: [{label: op.label, correct: op.correct, selected: op.correct}, ....]
//   }, .....]
//
// >> init (POST) function is used to create currents quiz version tied to user >>
// >> so if user starts a quiz, it'll create data about: quizId, userId and array of answers: [[false, false, false](question 1), [false, false](question 2...]
// and when user saves it'll modify the array of answers
//
// >> save (POST) function is used to save only the answers to the "init()" functions created entry
// save function format =>
// [[false, true, true], [true, false]...]
//
SettingBtns.QuestionnaireEdit = {
    init: PropTypes.func.isRequired,
    data: PropTypes.func.isRequired,
    save: PropTypes.func.isRequired,
    success: PropTypes.func // On successful submit click
};
