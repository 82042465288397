import { userConstants } from '../_constants';
import { api, history, openWSConnection } from '../_library';
import config from '../config';
import { i18nActions } from './i18n.actions';


export const userActions = {
    loadSettings,
    login,
    logout,
    switchClient,
    updatePaymentPlan
};

function loadSettings() {
    return dispatch => {

        const lang = localStorage.getItem(config.language);

        dispatch(i18nActions.changeLanguage(lang));

        api.get('/user/profile')
            .then(res => {
                dispatch(success(res.data));
                openWSConnection();
            }).catch(() => {
                localStorage.removeItem(config.accessTokenName);
                dispatch(reset());
            });
    };

    function success(data) { return { type: userConstants.LOAD_SETTINGS_SUCCESS, data } }
    function reset() { return { type: userConstants.RESET_SETTINGS } }
}

function login(firstLogin = false) {
    return dispatch => {
        api.get('/user/profile')
            .then(res => {
                dispatch(success(res.data));
                if (!firstLogin) {
                    if (res.data.currentClient.role === 'doctor') {
                        history.push('/doctor/dashboard');
                    } else if (res.data.currentClient.role === 'patient') {
                        history.push('/patient/dashboard');
                    } else {
                        history.push('/admin/patients');
                    }
                } else {
                    if (res.data.currentClient.role === 'doctor') {
                        history.push('/doctor/profile');
                    } else {
                        history.push('/signup/questionnaire');
                    }
                }
            }).catch(error => {
                dispatch(logout());
                dispatch(failure(error));
        });
    };

    function success(data) { return { type: userConstants.LOAD_SETTINGS_SUCCESS, data } }
    function failure(error) { return { type: userConstants.LOAD_SETTINGS_FAILURE, error } }
}

function logout() {
    return dispatch => {
        localStorage.removeItem(config.accessTokenName);
        history.push('/');
        dispatch(success());
    };

    function success() { return { type: userConstants.RESET_SETTINGS } }
}

function switchClient(clientId) {
    return dispatch => {

        const lang = localStorage.getItem(config.language);

        dispatch(i18nActions.changeLanguage(lang));

        api.post(`/user/switch/client/${clientId}`)
            .then(res => {
                dispatch(success(res.data.user));
                localStorage.setItem(config.accessTokenName, res.data.accessToken);

                if (res.data.user.currentClient.role === 'doctor') {
                    history.push('/doctor/dashboard');
                } else if (res.data.user.currentClient.role === 'patient') {
                    history.push('/patient/dashboard');
                } else {
                    history.push('/admin/patients');
                }
            }).catch(() => {});
    };

    function success(data) { return { type: userConstants.SWITCH_CLIENT_SUCCESS, data } }
}

function updatePaymentPlan(plan) {
    return dispatch => {

        api.get('/user/profile')
            .then(res => {
                dispatch(success(res.data));
            }).catch(error => {});

        dispatch({type: userConstants.UPDATE_PAYMENT_PLAN, plan});
    };

    function success(data) { return { type: userConstants.LOAD_SETTINGS_SUCCESS, data } }
}
