import React, { useState, useMemo, useEffect } from 'react';
import {FormLabel, FormSelect} from './';
import moment from 'moment';
import PropTypes from 'prop-types';


export const InlineDatepicker = props => {

    const [year, setYear] = useState('');
    const [month, setMonth] = useState('');
    const [day, setDay] = useState('');
    const [hour, setHour] = useState('');
    const [minute, setMinute] = useState('');

    const years = useMemo(() => {
        const years = [];
        const dateEnd = moment().subtract((props.dateIsBirthDate ? 18 : (props.futureDate ? -10 : 0)), 'y');
        const dateStart = moment().subtract((props.dateIsBirthDate ? 118 : (props.futureDate ? 0 : 100)), 'y');

        if (props.displayYearAsc) {
            while (dateEnd.diff(dateStart, 'years') >= 0) {
                years.push({name: dateStart.format('YYYY').toString(), value: dateStart.format('YYYY').toString()});
                dateStart.add(1, 'year');
            }
        } else {
            while (dateEnd.diff(dateStart, 'years') >= 0) {
                years.unshift({name: dateStart.format('YYYY').toString(), value: dateStart.format('YYYY').toString()});
                dateStart.add(1, 'year');
            }
        }

        return years
    }, [props.dateIsBirthDate]);

    const hours = (() => {

        const allHours = [];

        for (let i = 0; i < 24; i++) {
            allHours.push({name: (i < 10 ? '0' : '') + i.toString(), value: (i < 10 ? '0' : '') + i.toString()});
        }

        return allHours;
    })();

    const minutes = (() => {

        const allMinutes = [];

        for (let i = 0; i < 60; i++) {
            allMinutes.push({name: (i < 10 ? '0' : '') + i.toString(), value: (i < 10 ? '0' : '') + i.toString()});
        }

        return allMinutes;
    })();

    const months = useMemo(() => {

        const monthsOptions = [
            {name: 'Jan', value: '1'},
            {name: 'Feb', value: '2'},
            {name: 'Mar', value: '3'},
            {name: 'Apr', value: '4'},
            {name: 'Maijs', value: '5'},
            {name: 'Jūn', value: '6'},
            {name: 'Jūl', value: '7'},
            {name: 'Aug', value: '8'},
            {name: 'Sep', value: '9'},
            {name: 'Okt', value: '10'},
            {name: 'Nov', value: '11'},
            {name: 'Dec', value: '12'}
        ];

        if (props.dateIsBirthDate && year !== '') {
            const age = moment(year + '.1.' + (day !== '' ? day : '1'), 'YYYY.M.D');

            const date = moment.duration(moment().diff(age));

            if (date.years() === 18) {

                if (month !== '') {
                    if (parseInt(month) > date.months()) {
                        setMonth('1');
                    }
                }

                return monthsOptions.splice(0, date.months());
            }
        }

        return monthsOptions;
    }, [year, props.dateIsBirthDate]);

    const days = useMemo(() => {
        if (year === '' || month === '') {
            return new Array(31).fill(null).map((x, i) => ({name: (i + 1).toString(), value: (i + 1).toString()}));
        }

        return new Array(moment(year + month, "YYYYM").daysInMonth()).fill(null)
            .map((x, i) => (
                {name: moment(year + month, "YYYYM").startOf('month').add(i, 'days').format('D').toString(),
                    value: moment(year + month, "YYYYM").startOf('month').add(i, 'days').format('D').toString()}
            ));
    }, [year, month]);

    useEffect(() => {

        if (year === '' || month === '' || day === '') {
            return;
        }

        if (years.findIndex(y => y.value === year) === -1 || months.findIndex(m => m.value === month) === -1 || days.findIndex(d => d.value === day) === -1) {
            if (props.onChange !== undefined) {
                props.onChange('');
            }
            return;
        }

        if (props.onChange !== undefined) {
            props.onChange(moment(year + '.' + month + '.' + day, 'YYYY.M.D'));
        }

    }, [year, month, day]);

    useEffect(() => {

        if (year === '' || month === '' || day === '') {
            return;
        }

        if (years.findIndex(y => y.value === year) === -1 || months.findIndex(m => m.value === month) === -1 || days.findIndex(d => d.value === day) === -1) {
            if (props.onChange !== undefined) {
                props.onChange('');
            }
            return;
        }

        if (props.onChange !== undefined) {
            props.onChange(moment(year + '.' + month + '.' + day + ' ' + hour + ':' + minute, 'YYYY.M.D HH:mm'));
        }
    }, [hour, minute]);

    useEffect(() => {
        if (props.date !== null) {
            const date = moment(props.date);

            if (props.dateIsBirthDate) {
                const duration = moment.duration(moment().diff(date));

                if (duration.years() < 18) {
                    props.onChange('');
                    return;
                }
            }

            if (!date.isValid()) {
                props.onChange('');
                return;
            }

            setYear(date.format('YYYY'));
            setMonth(date.format('M'));
            setDay(date.format('D'));
            setHour(date.format('H'));
            setMinute(date.format('m'));
        }
    }, [props.date]);

    return (
        <div>
            <FormLabel label={props.label} name={props.name}/>
            <div className="grid grid-flow-col">
                <div>
                    <FormSelect
                      className="mr-2"
                      placeholder="sv.inline_datepicker.year"
                      options={years}
                      selected={year}
                      name="year"
                      keyName="name"
                      reverse={true}
                      onChange={val => setYear(val)}
                      noTopMargin={props.noTopMargin}
                      smallHeight={props.smallHeight}
                      notLabels
                      showLabel={false}
                    />
                </div>
                <div>
                    <FormSelect
                      className="mr-2"
                      placeholder="sv.inline_datepicker.month"
                      options={months}
                      selected={month}
                      name="month"
                      keyName="name"
                      reverse={true}
                      onChange={val => setMonth(val)}
                      noTopMargin={props.noTopMargin}
                      smallHeight={props.smallHeight}
                      notLabels
                      showLabel={false}
                    />
                </div>
                <div>
                    <FormSelect
                      className="mr-2"
                      placeholder="sv.inline_datepicker.day"
                      options={days}
                      selected={day}
                      name="day"
                      keyName="name"
                      reverse={true}
                      onChange={val => setDay(val)}
                      noTopMargin={props.noTopMargin}
                      smallHeight={props.smallHeight}
                      notLabels
                      showLabel={false}
                    />
                </div>
                {props.withTime &&
                    <>
                        <div>
                            <FormSelect
                              className="mr-2"
                              placeholder="sv.inline_datepicker.hours"
                              options={hours}
                              selected={hour}
                              name="hour"
                              keyName="name"
                              reverse={true}
                              onChange={val => setHour(val)}
                              noTopMargin={props.noTopMargin}
                              smallHeight={props.smallHeight}
                              notLabels
                              showLabel={false}
                            />
                        </div>
                        <div>
                            <FormSelect
                              className="mr-2"
                              placeholder="sv.inline_datepicker.minutes"
                              options={minutes}
                              selected={minute}
                              name="minute"
                              keyName="name"
                              reverse={true}
                              onChange={val => setMinute(val)}
                              noTopMargin={props.noTopMargin}
                              smallHeight={props.smallHeight}
                              notLabels
                              showLabel={false}
                            />
                        </div>
                    </>
                }
            </div>
        </div>
    );

};

InlineDatepicker.protoTypes = {
    date: PropTypes.object,
    onChange: PropTypes.object,
    label: PropTypes.string.isRequired,
    dateIsBirthDate: PropTypes.bool,
    noTopMargin: PropTypes.bool,
};
