import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';


export const Button = props => {

    let colors = 'bg-sv-p hover:bg-sv-p-d focus:ring-sv-p-l text-white ';

    if (props.secondary) {
        colors = 'bg-sv-s hover:bg-sv-s-d focus:ring-sv-s-l text-gray-900 ';
    }

    switch(props.color){
        case 'red':
            colors = 'bg-red-500 hover:bg-red-600 focus:bg-red-400 text-white ';
            break;
        case 'green':
            colors = 'bg-green-500 hover:bg-green-600 focus:bg-green-400 text-white ';
            break;
        case 'neutral':
            colors = 'bg-gray-300 hover:bg-gray-400 focus:bg-gray-200 text-gray-700 ';
            break;
        case 'orange':
            colors = 'bg-sv-orange hover:bg-sv-orange-l text-black rounded-xl font-light font-manropeRegular'
    }


    return(
        <button
            type={props.type ? props.type : 'button'}
            {...(props.onClick ? {onClick: props.onClick} : {})}
            className={'group relative flex justify-center border min-w-64 ' +
            'border-transparent text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 ' +
            'disabled:opacity-50 ' + (props.small ? 'py-2 px-10 ' : (props.extraSmall ? 'py-1 px-5 ' : 'py-3 px-10 ')) + colors + (props.className || '')}
            disabled={props.disabled}
        >
            {props.icon &&
                <span className={'' + (props.label ? 'pr-1' : '')}>
                    {props.icon}
                </span>
            }
            {props.children}
            {props.label &&
                <FormattedMessage id={props.label}/>
            }
            {props.staticLabel}
        </button>
    );
};

Button.propTypes = {
    type: PropTypes.oneOf(['button', 'submit', 'reset']),
    staticLabel: PropTypes.string,
    onClick: PropTypes.func,
    className: PropTypes.string,
    label: PropTypes.string,
    icon: PropTypes.elementType,
    disabled: PropTypes.bool,
    secondary: PropTypes.bool,
    color: PropTypes.oneOf(['red', 'green', 'neutral', 'orange'])
};
