import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import { useField } from 'formik';
import { FormInputWrapper } from './';


export const FormInput = props => {

    const intl = useIntl();
    const [field, meta] = useField(props.name);
    const Tag = props.tag || 'input';

    return(
        <FormInputWrapper className={props.className} width={props.width} meta={meta} name={props.name} label={props.label} labelPosition={props.labelPosition} labelWidth={props.labelWidth} noLabel={props.noLabel} noError={props.noError}>
            <Tag
                {...field}
                {...(props.textareaRows ? {rows: props.textareaRows} : {})}
                {...(props.format ? {format: props.format} : {})}
                type={props.type ? props.type : 'text'}
                name={props.name}
                id={props.name}
                onClick={props.onClick}
                disabled={props.disabled}
                {...(props.max ? {maxlength: props.max} : {})}
                {...(props.value ? {value: props.value} : {})}
                placeholder={intl.formatMessage({id: props.placeholder})}
                className={'h-full border-gray-300 placeholder-gray-400 rounded-sm shadow-sm focus:ring-sky-600 focus:border-sky-500 block text-black ' + ((meta.value === '' && 'text-gray-400 ') || '') +
                'w-full shadow-sm sm:text-sm ' + ((meta.touched && meta.error) ? 'border-red-500 focus:border-red-600 focus:ring-red-600 ' : '')}
            />
        </FormInputWrapper>
    );
};

FormInput.propTypes = {
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    value: PropTypes.string,
    type: PropTypes.string,
    label: PropTypes.string,
    className: PropTypes.string,
    tag: PropTypes.string,
    format: PropTypes.string,
    textareaRows: PropTypes.number,
    onClick: PropTypes.object,
    disabled: PropTypes.bool,
    noLabel: PropTypes.bool,
    updateMeta: PropTypes.func
};
